var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "myPage",
        staticStyle: { height: "calc(100vh - 50px)" },
        on: {
          click: function ($event) {
            _vm.isShowNodeMenuPanel = false
          },
        },
      },
      [
        _c(
          "el-button",
          {
            on: {
              click: function ($event) {
                return _vm.$router.go(-1)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("cip.dc.PrimordialDComission.back")))]
        ),
        _c("RelationGraph", {
          ref: "seeksRelationGraph",
          attrs: {
            options: _vm.graphOptions,
            "on-node-click": _vm.onNodeClick,
            "on-line-click": _vm.onLineClick,
          },
          scopedSlots: _vm._u([
            {
              key: "node",
              fn: function ({ node }) {
                return _c("div", {}, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "80px",
                        "line-height": "80px",
                        "border-radius": "50%",
                        cursor: "pointer",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.showNodeMenus(node, $event)
                        },
                        contextmenu: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.showNodeMenus(node, $event)
                        },
                        mouseover: function ($event) {
                          return _vm.nodeSlotOver(node, $event)
                        },
                        mouseout: function ($event) {
                          return _vm.nodeSlotOut(node, $event)
                        },
                      },
                    },
                    [
                      _c("i", {
                        class: node.data.myicon,
                        staticStyle: { "font-size": "30px" },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        color: "forestgreen",
                        "font-size": "16px",
                        position: "absolute",
                        width: "160px",
                        height: "25px",
                        "line-height": "25px",
                        "margin-top": "5px",
                        "margin-left": "-48px",
                        "text-align": "center",
                        "background-color": "rgba(66,187,66,0.2)",
                      },
                    },
                    [_vm._v("\n          " + _vm._s(node.text) + "\n        ")]
                  ),
                ])
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }