<template>
  <div>
    <div ref="myPage" style="height:calc(100vh - 50px);" @click="isShowNodeMenuPanel = false">
      <el-button @click="$router.go(-1)">{{ $t('cip.dc.PrimordialDComission.back') }}</el-button>
      <RelationGraph
        ref="seeksRelationGraph"
        :options="graphOptions"
        :on-node-click="onNodeClick"
        :on-line-click="onLineClick">
        <div slot="node" slot-scope="{node}">
          <div
            style="height:80px;line-height: 80px;border-radius: 50%;cursor: pointer;"
            @click="showNodeMenus(node, $event)"
            @contextmenu.prevent.stop="showNodeMenus(node, $event)"
            @mouseover="nodeSlotOver(node, $event)"
            @mouseout="nodeSlotOut(node, $event)"
          >
            <i style="font-size: 30px;" :class="node.data.myicon" />
          </div>
          <div style="color: forestgreen;font-size: 16px;position: absolute;width: 160px;height:25px;line-height: 25px;margin-top:5px;margin-left:-48px;text-align: center;background-color: rgba(66,187,66,0.2);">
            {{ node.text }}
          </div>
        </div>
      </RelationGraph>
    </div>

  </div>
</template>

<script>
// 如果您没有在main.js文件中使用Vue.use(RelationGraph); 就需要使用下面这一行代码来引入relation-graph
import RelationGraph from 'relation-graph';
export default {
  name: 'Demo',
  components: { },
  data() {
    return {
      isShowCodePanel: false,
      isShowNodeMenuPanel: false,
      nodeMenuPanelPosition: { x: 0, y: 0 },
      graphOptions: {
        allowSwitchLineShape: true,
        allowSwitchJunctionPoint: true,
        defaultJunctionPoint: 'border'
        // 这里可以参考"Graph 图谱"中的参数进行设置
      },
      __graph_json_data:{}
    }

  },
  mounted() {
    this.getPrimordialData()

    this.showSeeksGraph();
  },
  methods: {
    showSeeksGraph() {
      const __graph_json_data = {
        rootId: '2',
        nodes: [
          // 注意：在节点配置信息中，你的自定义属性需要像下面这样放到data标签中，否则数据会丢失
          { id: '1', name: '节点-1', data: { myicon: 'el-icon-star-on' }},
          { id: '2', name: '节点-2', data: { myicon: 'el-icon-setting' }},
          { id: '3', name: '节点-3', data: { myicon: 'el-icon-setting' }},
          { id: '4', name: '节点-4', data: { myicon: 'el-icon-star-on' }},
          { id: '6', name: '节点-6', data: { myicon: 'el-icon-setting' }},
          { id: '7', name: '节点-7', data: { myicon: 'el-icon-setting' }},
          { id: '8', name: '节点-8', data: { myicon: 'el-icon-star-on' }},
          { id: '9', name: '节点-9', data: { myicon: 'el-icon-headset' }},
          { id: '71', name: '节点-71', data: { myicon: 'el-icon-headset' }},
          { id: '72', name: '节点-72', data: { myicon: 'el-icon-s-tools' }},
          { id: '73', name: '节点-73', data: { myicon: 'el-icon-star-on' }},
          { id: '81', name: '节点-81', data: { myicon: 'el-icon-s-promotion' }},
          { id: '82', name: '节点-82', data: { myicon: 'el-icon-s-promotion' }},
          { id: '83', name: '节点-83', data: { myicon: 'el-icon-star-on' }},
          { id: '84', name: '节点-84', data: { myicon: 'el-icon-s-promotion' }},
          { id: '85', name: '节点-85', data: { myicon: 'el-icon-sunny' }},
          { id: '91', name: '节点-91', data: { myicon: 'el-icon-sunny' }},
          { id: '92', name: '节点-82', data: { myicon: 'el-icon-sunny' }},
          { id: '51', name: '节点-51', data: { myicon: 'el-icon-sunny' }},
          { id: '52', name: '节点-52', data: { myicon: 'el-icon-sunny' }},
          { id: '53', name: '节点-53', data: { myicon: 'el-icon-sunny' }},
          { id: '54', name: '节点-54', data: { myicon: 'el-icon-sunny' }},
          { id: '55', name: '节点-55', data: { myicon: 'el-icon-sunny' }},
          { id: '5', name: '节点-5', data: { myicon: 'el-icon-sunny' }}
        ],
        lines: [
          { from: '7', to: '71', text: '投资' },
          { from: '7', to: '72', text: '投资' },
          { from: '7', to: '73', text: '投资' },
          { from: '8', to: '81', text: '投资' },
          { from: '8', to: '82', text: '投资' },
          { from: '8', to: '83', text: '投资' },
          { from: '8', to: '84', text: '投资' },
          { from: '8', to: '85', text: '投资' },
          { from: '9', to: '91', text: '投资' },
          { from: '9', to: '92', text: '投资' },
          { from: '5', to: '51', text: '投资1' },
          { from: '5', to: '52', text: '投资' },
          { from: '5', to: '53', text: '投资3' },
          { from: '5', to: '54', text: '投资4' },
          { from: '5', to: '55', text: '投资' },
          { from: '1', to: '2', text: '投资' },
          { from: '3', to: '1', text: '高管' },
          { from: '4', to: '2', text: '高管' },
          { from: '6', to: '2', text: '高管' },
          { from: '7', to: '2', text: '高管' },
          { from: '8', to: '2', text: '高管' },
          { from: '9', to: '2', text: '高管' },
          { from: '1', to: '5', text: '投资' }
        ]
      };
      this.$refs.seeksRelationGraph.setJsonData(this.__graph_json_data, (graphInstance) => {
        // 这些写上当图谱初始化完成后需要执行的代码
      });
    },
    getPrimordialData(){
      this.__graph_json_data=JSON.parse( localStorage.getItem('PRIMORD-DATA'))
      console.log()
      const lines=JSON.parse(this.__graph_json_data.lines)
      const nodes=JSON.parse(this.__graph_json_data.nodes)
      this.__graph_json_data.lines=lines
      this.__graph_json_data.nodes=nodes
      console.log(this.__graph_json_data,'sss')
      // console.log(JSON.parse( localStorage.getItem('PRIMORD-DATA')),'JSON.parse( localStorage.getItem(\'PRIMORD-DATA\'))\n')

    },
    onNodeClick(nodeObject, $event) {
      console.log('onNodeClick:', nodeObject);
    },
    onLineClick(lineObject, $event) {
      console.log('onLineClick:', lineObject);
    },
    nodeSlotOver(nodeObject) {
      console.log('nodeSlotOver:', nodeObject);
    },
    nodeSlotOut(nodeObject) {
      console.log('nodeSlotOut:', nodeObject);
    },
    showNodeMenus(nodeObject, $event) {
      this.currentNode = nodeObject;
      const _base_position = this.$refs.myPage.getBoundingClientRect();
      console.log('showNodeMenus:', $event, _base_position);
      this.isShowNodeMenuPanel = true;
      this.nodeMenuPanelPosition.x = $event.clientX - _base_position.x;
      this.nodeMenuPanelPosition.y = $event.clientY - _base_position.y;
    },
    doAction(actionName) {
      this.$notify({
        title: '提示',
        message: '对节点【' + this.currentNode.text + '】进行了：' + actionName,
        type: 'success'
      });
      this.isShowNodeMenuPanel = false;
    }
  }
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
.c-node-menu-item{
  line-height: 30px;padding-left: 10px;cursor: pointer;color: #444444;font-size: 14px;border-top:#efefef solid 1px;
}
.c-node-menu-item:hover{
  background-color: rgba(66,187,66,0.2);
}
</style>
